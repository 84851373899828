<!--
 * @Author: your name
 * @Date: 2021-11-11 14:31:27
 * @LastEditTime: 2021-11-29 17:36:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\team-building\chart-display\index.vue
-->
<template>
  <div class="echart-display">
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="所属项目部">
          <el-select
            v-model="queryForm.projectDepartmentIds"
            placeholder="请选择项目部"
            multiple
            collapse-tags
            @change="changeProjectList"
          >
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="演练类别">
          <el-select
            v-model="queryForm.drillCategoryIds"
            placeholder="请选择演练类别"
            multiple
            collapse-tags
            @change="changeStatusList"
          >
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>
<script>
import { getProjectDepartmentList } from 'api/common';
import echartsLine from 'components/EchartsLine';
import { getConfigDetailList, getPhotoData } from '@/api/general-user/emergency-rescue.js';

import { cloneDeep } from 'lodash';

export default {
  components: {
    echartsLine
  },
  data() {
    return {
      queryForm: {
        projectDepartmentIds: [],
        drillCategoryIds: []
      },
      statusList: [],
      projectList: [],
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      xData: [], //图表横坐标
      dataList: [] //图表数据
    };
  },
  mounted() {},
  created() {
    this.getProjectDepartmentList();
    this.getConfigDetailList();
    this.getPhotoData();
  },
  methods: {
    getProjectDepartmentList() {
      //这个单独接口获取(所属项目部)
      getProjectDepartmentList().then(res => {
        if (res.code === 200) {
          let { data } = res;
          //true自定义不加全选，反之
          data.unshift({ name: '全选', id: '' });
          this.projectList = data;
        }
      });
    },
    getConfigDetailList() {
      //这个单独接口获取(所属项目部)
      getConfigDetailList({ module: 4 }).then(res => {
        if (res.code === 200) {
          let { data } = res;
          //true自定义不加全选，反之
          // data.unshift({ name: '全选', id: '' });
          data.find(item => {
            if (item.code === 'four_category') {
              this.statusList = item.downList;
            }
          });
          this.statusList.unshift({ name: '全选', id: '' });
        }
      });
    },
    changeProjectList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.projectDepartmentIds = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.projectDepartmentIds = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.projectDepartmentIds = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    changeStatusList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.statusList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.drillCategoryIds = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.drillCategoryIds = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.drillCategoryIds = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    getPhotoData() {
      //项目部危险源数量总数（默认）简单柱状图
      let params = cloneDeep(this.queryForm);
      if (params.projectDepartmentIds.includes('')) {
        //判断数组中是否有空字符串
        params.projectDepartmentIds = []; //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.drillCategoryIds.includes('')) {
        params.drillCategoryIds = params.drillCategoryIds.filter(item => item !== '');
      }
      getPhotoData(params).then(res => {
        if (res.code === 200) {
          const { drillCategoryInfoList, nameList, allNum } = res.data;
          this.xData = nameList; //横坐标的值
          if (drillCategoryInfoList !== '') {
            //只要选择了其他下拉选择，dangerSourcePhotoResults字段就有值
            drillCategoryInfoList.forEach(item => {
              item.type = 'bar';
              item.stack = 'bar';
              item.barWidth = 20;
              item.data = item.drillCategoryNumList;
            });
            this.dataList = drillCategoryInfoList;
          } else {
            //只选择了所属项目部
            this.dataList = [
              {
                data: allNum,
                type: 'bar',
                barWidth: 20,
                backgroundColor: '#1890FF',
                name: '演练次数'
              }
            ];
          }
        }
        this.$nextTick(() => {
          this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
        });
      });
    }
  }
};
</script>
